
import Vue from 'vue';
import getBannerKey from '@root/modules/ads/utils/getBannerKey';
import ContentLayoutInner from './ContentLayoutInner.vue';

type Items = Record<string, any>[];

interface Props {
  styles: string | null;
  classes: Record<string, boolean>;
  showContent: boolean;
  index: number;
  items: Items;
  insertBannerAfterItems: number;
  insertBannerAfterSection: boolean;
  insertBannerTower: boolean;
  allowFullWidth: boolean;
  rowClass: boolean;
}

interface Computed {
  contentLoop: number;
  justifyContent: boolean;
  innerProps: Record<string, unknown>;
  itemSlotName: string;
  disabledAdsList: string[];
}

interface Methods {
  getKey: (params: Record<string, any>) => string;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    ContentLayoutInner,
  },
  props: {
    styles: {
      type: String,
      required: false,
      default: '',
    },
    classes: {
      type: Object,
      required: false,
      default: null,
    },
    showContent: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    items: {
      type: Array,
      required: true,
    },
    insertBannerAfterItems: {
      type: Number,
      default: 0,
      required: false,
    },
    insertBannerAfterSection: {
      type: Boolean,
      default: false,
      required: false,
    },
    insertBannerTower: {
      type: Boolean,
      default: true,
      required: false,
    },
    allowFullWidth: {
      type: Boolean,
      default: false,
      required: false,
    },
    rowClass: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    // Use v-slot:item when your list is made of rows or regular items
    // Use v-slot:row when your list is made of cols
    itemSlotName() {
      // Eslint error is wrong because $scopedSlots.row is undefined or function
      // eslint-disable-next-line no-extra-boolean-cast
      return !!this.$scopedSlots.row ? 'row' : 'item';
    },
    contentLoop() {
      const bannerCount = this.insertBannerAfterItems;
      const count = bannerCount > 0 ? this.items.length / this.insertBannerAfterItems : bannerCount;

      // Round number up to nearest even number
      const loopAmount = count > 1 ? Math.ceil(count) : 1;

      return loopAmount;
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
    justifyContent() {
      return !this.allowFullWidth && this.disabledAdsList.includes('tower') && this.rowClass;
    },
    innerProps() {
      const { insertBannerAfterItems, styles, classes, itemSlotName, justifyContent } = this;
      const isCol = { col: this.rowClass };
      const hasRow = { row: itemSlotName === 'row' };
      const hasBannerOnSide = { 'col-lg-9 flex-fill': justifyContent };

      return {
        insertBannerAfterItems,
        style: styles,
        class: [isCol, hasRow, hasBannerOnSide, classes],
      };
    },
  },
  methods: {
    getKey({ prefix, index }) {
      return getBannerKey({ prefix, route: this.$route, store: this.$store, index });
    },
  },
});
