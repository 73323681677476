
import Vue from 'vue';

type Items = Record<string, any>[];

interface Props {
  items: Items;
  index: number;
  insertBannerAfterItems: number;
}

interface Computed {
  getItems: Items;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    items: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
    insertBannerAfterItems: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  computed: {
    getItems() {
      const bannerCount = this.insertBannerAfterItems;

      if (bannerCount === 0) {
        return this.items;
      }

      // v-for="index in contentLoop" loop starts at 1
      const i = this.index - 1;
      const start = i * bannerCount;
      const end = start + bannerCount;

      return this.items.slice(start, end);
    },
  },
  // Primarily captures Headlines Blocks errors
  errorCaptured(err, vm, info) {
    const { componentOptions } = vm.$vnode;
    const propsData = componentOptions?.propsData;
    const componentName = vm.$vnode.componentOptions?.tag || 'unknown component';
    this.$sentry.captureException(err, {
      contexts: { vue: { componentName, propsData, lifecycleHook: info } },
      tags: { 'process.server': process.server ? 'server' : 'client' },
    });

    // Stop the propagation of errors further
    // to parent or global error handler to not
    // throw 500 because of 1 faulty block
    return false;
  },
});
