
import Vue from 'vue';
import LocationHeader from '@root/modules/weather/components/location/LocationHeader.vue';
import WeatherService from '@root/modules/weather/services/Weather.service';
import Loader from '@root/common/components/base/loader/Loader.vue';

interface Variables {
  placeId?: string;
  lon?: string;
  lat?: string;
}
interface Data {
  loading: boolean;
  variables: Variables;
  weather: Record<string, unknown> | null;
}
interface Computed {
  variablesLocal: Variables;
}
interface Methods {
  getNewLocation: (location: Variables) => void;
  getWeather: () => void;
}

export default Vue.extend<Data, unknown, Computed, Methods>({
  components: {
    Loader,
    LocationHeader,
  },
  data() {
    return {
      loading: true,
      variables: {
        placeId: 'ChIJ9QhkXPuT3UYRQNzpcIzRAAQ',
      },
      weather: null,
    };
  },
  computed: {
    variablesLocal() {
      return this.variables;
    },
  },
  async mounted() {
    this.getWeather();
  },
  methods: {
    getNewLocation(location: any) {
      this.variables = location;
      this.getWeather();
    },
    async getWeather() {
      const weatherService = new WeatherService();
      const data = await weatherService.fetch({ hourlyTime: 52, dailyTime: 40, ...this.variablesLocal });
      this.weather = data;
      this.loading = false;
    },
  },
});
