
import Vue from 'vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import AdsLayout from '@root/common/components/layout/AdsLayout.vue';
import ContentLayout from '@root/common/components/layout/ContentLayout.vue';
import HeadlineBlocks from '@root/common/components/base/headline/HeadlineBlocks.vue';
import WeatherWidget from '@root/modules/weather/components/WeatherWidget.vue';
import { Section } from '@root/modules/frontpage/types/frontpage';
import CategoryInfo from '@root/modules/category/components/CategoryInfo.vue';
import { Data as CategoriesData } from '@root/modules/category/types/categories';
import isMobileView from '@root/common/utils/isMobileView';
import getBannerKey from '@root/modules/ads/utils/getBannerKey';

interface Props {
  feed: Section;
  category?: Omit<CategoriesData, 'channel'> | null;
  type: string;
  limit: number;
}

interface Methods {
  getSectionSettings: (settings: Section[0]['attributes']['settings']) => {
    insertBannerAfterSection: boolean;
    insertBannerTower: boolean;
    styles: string | null;
    classes: Record<string, boolean>;
  };
  getPlacement: (index: number, type: string) => string;
  getKey: (params: Record<string, any>) => string;
  getAdsConfig: (id: string) => Record<string, any> | null;
  getAdSpacer: (seciton: Section[0]) => boolean;
  getTowerAds: (section: Section[0]) => Record<string, any> | null;
  getCornerAd: (section: Section[0]) => Record<string, any> | null;
  getMobileAd: (section: Section[0]) => Record<string, any> | null;
  getSideMenuConfig: (id: string) => Record<string, any> | null;
}

interface Computed {
  adPlacements: Record<string, any>;
  channelTitle: string | null;
  disabledAdsList: string[];
}

interface Data {
  isMobileView: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AdFormBanner,
    AdsLayout,
    ContentLayout,
    HeadlineBlocks,
    CategoryInfo,
    WeatherWidget,
  },
  props: {
    feed: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isMobileView: false,
    };
  },
  computed: {
    adPlacements() {
      const placements: Record<string, any> = this.$store.getters['adForm/getAdsPlacements'];

      return placements || {};
    },
    channelTitle() {
      return this.$channelConfig('header').navigation.bottom.settings.title || null;
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
  },
  mounted() {
    this.isMobileView = isMobileView();
  },
  methods: {
    getSectionSettings(settings) {
      const bannerPlacement = settings.bannerPlacement;
      const insertBannerAfterSection = bannerPlacement === 'bottom' || bannerPlacement === 'bottom_and_side';
      const insertBannerTower = (bannerPlacement === 'side' || bannerPlacement === 'bottom_and_side') && this.type === 'default';
      const styles = settings.background ? `background-color: ${settings.background}` : null;

      const colorClassName = !!settings.background && settings.background !== 'transparent' ? 'bg-' + settings.background.replace('#', '') : '';

      const groupClass = {
        'group-has-bg': !!settings.background && settings.background !== 'transparent' && settings.background !== '#00000000',
        ...(colorClassName && { [`${colorClassName}`]: true }),
      };

      return {
        styles,
        classes: groupClass,
        insertBannerAfterSection,
        insertBannerTower,
      };
    },
    getAdsConfig(id) {
      const adsSettings = this.$channelConfig('page').frontpage?.component?.adForm || [];

      return adsSettings.find((section: any) => section.id === id);
    },
    getAdSpacer(section) {
      const sectionAdsConfig = this.getAdsConfig(section.id);

      return (sectionAdsConfig && sectionAdsConfig.spacer) || section.attributes.settings.spacer || false;
    },
    getTowerAds(section) {
      const sectionAdsConfig = this.getAdsConfig(section.id);

      return (sectionAdsConfig && sectionAdsConfig.tower) || section.attributes.settings.ads || null;
    },
    getCornerAd(section) {
      const sectionAdsConfig = this.getAdsConfig(section.id);

      return (sectionAdsConfig && sectionAdsConfig.corner) || null;
    },
    getMobileAd(section) {
      const sectionAdsConfig = this.getAdsConfig(section.id);

      return (sectionAdsConfig && sectionAdsConfig.mobile) || null;
    },
    getPlacement(index, type) {
      const length = this.feed.length;
      const placements = this.adPlacements[type].mid;
      const loop = Math.ceil(placements.length / length);
      let placement = placements[index];
      let newIndex = null;

      // Loop again through placements
      if (index >= placements.length) {
        newIndex = index - placements.length * loop;
        placement = placements[newIndex];
      }

      return placement;
    },
    getKey({ prefix, index }) {
      return getBannerKey({ prefix, route: this.$route, store: this.$store, index });
    },
    getSideMenuConfig(id) {
      const sideMenuSettings = this.$channelConfig('page').frontpage?.component?.sideMenu || [];

      return sideMenuSettings.find((section: any) => section.id === id);
    },
  },
});
