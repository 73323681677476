
import Vue from 'vue';
import { Data as CategoryData } from '@root/modules/category/types/categories';
import { CategoryBase } from '@root/common/types/fragment';

interface Methods {
  categoryUrl: (category: CategoryBase) => string;
}

interface Props {
  category: CategoryData;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    categoryUrl(category) {
      const { locale } = this.$channelConfig('settings');
      return this.$options.filters!.categoryUrl({ category, locale });
    },
  },
});
