
import Vue from 'vue';
import { Data as HeadlinesData, HeadlineSettings } from '@root/modules/headlines/types/headlines';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import { BlockCollection } from '@headlines/components/block';

type HeadlineItems = {
  items: HeadlinesData[];
  tabs: {
    data: {
      items: HeadlinesData[];
    };
  }[];
  groups: {
    name: string;
    items: HeadlinesData[];
  }[];
};

interface Props {
  blockKey: string;
  blockType: string;
  data: HeadlineItems;
  settings: HeadlineSettings;
}

interface Computed {
  getHeadlinesData: unknown;
  hasChannelSubscription: boolean;
}

interface Methods {
  getHeadlinesWithHref: (data: HeadlinesData[]) => HeadlinesData[];
  getTabsWithHref: (data: HeadlineItems) => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    ...BlockCollection,
  },
  props: {
    blockKey: {
      type: String,
      required: false,
      default: '',
    },
    blockType: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getHeadlinesData() {
      const { data } = this;

      // Add href support for headlines
      if (data.items) {
        // Regular type block items
        if (Array.isArray(data.items)) {
          data.items = this.getHeadlinesWithHref(data.items);
        } else {
          // Feed type block items
          data.groups = [];
          for (const [name, items] of Object.entries(data.items)) {
            const itemsWithHref = this.getHeadlinesWithHref(items as HeadlinesData[]);
            data.groups.push({ name, items: itemsWithHref });
          }
        }
      }

      // Add href support for tabs headlines
      if (data.tabs) {
        const dataCopy = { ...data };
        this.getTabsWithHref(dataCopy);
      }

      return data;
    },
    hasChannelSubscription() {
      return this.$store.state.piano.access.channelAccess;
    },
  },
  methods: {
    getHeadlinesWithHref(items) {
      const articlesLinksHash = this.settings.articlesLinksHash;

      const { domain } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;

      const itemsWithHref = items.map((headline) => {
        if (headline.relatedArticles?.items && headline.relatedArticles.items.length > 0) {
          for (let i = 0; i < headline.relatedArticles.items.length; i++) {
            if (headline.relatedArticles.items[i]) {
              headline.relatedArticles.items[i] = getHeadlineWithHref({
                headline: headline.relatedArticles.items[i] as HeadlinesData,
                domain,
                fakeDomainEnabled,
                articlesLinksHash,
              });
            }
          }
        }

        return getHeadlineWithHref({ headline, domain, fakeDomainEnabled, articlesLinksHash });
      });
      return itemsWithHref;
    },
    getTabsWithHref(dataCopy) {
      const { domain } = this.$channelConfig('settings');
      const articlesLinksHash = this.settings.articlesLinksHash;
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;

      dataCopy.tabs.forEach((tab, index) => {
        const items = tab.data.items.map((headline) => getHeadlineWithHref({ headline, domain, fakeDomainEnabled, articlesLinksHash }));
        if (dataCopy.tabs[index]) {
          dataCopy.tabs[index].data.items = items;
        }
      });
    },
  },
});
